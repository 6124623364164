<template>
  <div class="box" @click="routeToCourse(link)">
    <div class="course-card-item">
      <a class="course-card-pic"
         :style="{'background-image': 'url(' + imageUrl + ')'}">
        <div v-if="tag" class="marker-group">
          <div class="marker-item">{{ tag }}</div>
        </div>
      </a>
      <div class="course-card-content">
        <div class="course-card-pretitle">{{ theme }}</div>
        <a class="course-card-title">{{ title }}</a>
        <div class="course-card-bar">
          <template v-if="isSigned">
            <a class="button-default">К курсу</a>
            <div class="course-card-percent">{{ progressPercent }} %</div>
          </template>
          <template v-else>
            <button class="button-color">Записаться</button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CourseCard',
  props: {
    theme: String,
    title: String,
    isSigned: Boolean,
    progressPercent: Number,
    tag: String,
    name: String,
    imageUrl: String,
    link: String,
  },

  data() {
    return {};
  },

  mounted() {
    this.$emit('input', this.selectedOption);
  },

  watch: {},

  methods: {
    routeToCourse(link) {
      this.$router.push({path: link,});
    },
  },

  directives: {},

  components: {},
};
</script>

<style lang="scss" scoped>
</style>